<template>
    
      <v-layout>       
          <AppBar />
          <v-main>
          
            <v-container>                     
              <v-card class="mx-auto " color="grey-lighten-4" v-if="user_valid">                        
                  <v-container style="background-color: black; color: white;">                          
                      
                      <h3 align="center">Current Channel: {{ current_channel }}</h3>   
                      <div align="center">
                      <iframe align="center" allow="autoplay; fullscreen; picture-in-picture; xr-spatial-tracking; encrypted-media"
                      allowfullscreen="true" webkitallowfullscreen="true" mozallowfullscreen="true" id="livetvplayer" :src="'https://mvids.org/live/stream.php?id=' +current_channel_index"
                      class="liveplayer"
                        height="450" style="width:50%; border-width: 0px;">    
                      </iframe>           
                    </div>                                 
                  </v-container>
              <v-card>
                <template v-slot:text>
              <v-text-field
                v-model="search"
                label="Search"
                prepend-inner-icon="mdi-magnify"
                variant="outlined"
                hide-details
                single-line
              ></v-text-field>
            </template>
                <v-data-table style="background-color: rgba(0,0,0,0.0);" 
                  calculate-widths="false"
                  
                    :headers="headers"
                    :items="channels"
                    :search="search"
                      @click:row="play_item" item-key="id"
                      
                  ></v-data-table>
              </v-card>
                </v-card> 
                <v-card class="mx-auto " color="grey-lighten-4" v-else>    
                  <h4 align="center" style="padding:15px;">This section is only accessible to members with active subscription</h4>
                </v-card>
 
            </v-container>
        </v-main>
        
    </v-layout>
 
  </template>
<script>
import axios  from 'axios';
import AppBar from '../components/AppBar.vue'
import {useUserStore } from '../stores/user'
export default {
  name: 'LiveTV',

  components: {  
    AppBar,  
  },

  data: () => ({
        search:"",
        user_valid: false,
        headers: [{ title: '#', value: 'id',align: 'start', width: 2},
                  { title: 'Channel', value: 'channel' }],
        channels: [],
        current_channel:"",
        current_channel_index:1,
  }),
  mounted() {
    
    
    const user = useUserStore();        
    console.log(user);
    var now = new Date();
    var exp = new Date(user.expiry_date);

    now.setHours(0,0,0,0);
    
    if (exp < now) {
      this.user_valid = false;
    } else {
      this.user_valid = true;
    }                              
    axios.post("https://mvids.org/live/get_channels.php")
            .then(response => {
                
                if(response.data.status == 0)
                alert(response.data.reason);
                else
                {
                    console.log(response.data.channels);
                    this.channels = response.data.channels;
                    this.current_channel = this.channels[0].channel;
                }
            })
            .catch(error => {
                this.errorMessage = error.message;
                console.error("There was an error!", error);
            });
 
    },
    methods: {
      toDay()
      {
        let today = new Date().toISOString().slice(0, 10)
        return today;
      },
      nextChannel()
      {
        this.current_channel_index += 1;     
        
        let item = this.channels[this.current_channel_index-1];   
        this.play_item(item,this.current_channel_index);
      },
      prevChannel()
      {
        this.current_channel_index -= 1;     
        let item = this.channels[this.current_channel_index-1];   
        
        this.play_item(item,this.current_channel_index);
      },
      play_item(event,row)
      {                 
          this.current_channel = row.item.channel;
          this.current_channel_index = row.item.id;
          window.scrollTo(0,0);
      }
    }
  
}
</script>