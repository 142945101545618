<template>
    
        <v-layout>       
            <AppBar />
            <v-main>
           
                <v-container>     
                  
                    <v-card class="mx-auto " color="grey-lighten-4" max-width="1000">                        
                        <v-container >                          
                            <h2 class="mx-auto">Billing Info</h2>                            
                            <p>Update your billing info here, all fields  are required</p>
                            <p>Monthly Fee: <b>{{user.cost}}</b> </p>
                        </v-container>
                    
                       
                    
                    <form @submit.prevent="submit" v-if="!HasBillingId">
                    <v-row  justify="center">
                    <v-col >
                        
                        <v-card class="ml-4 mx-auto mb-4" color="grey-lighten-4" max-width="800">  
                            <h4 class="ml-4 mt-4">Contact Information</h4>
                        <v-container >
                        
                            <v-text-field
                                v-model="name.value.value"
                                :counter="10"
                                :error-messages="name.errorMessage.value"
                                label="Name"
                            ></v-text-field>
                            <v-text-field
                                v-model="surname.value.value"
                                :counter="10"
                                :error-messages="surname.errorMessage.value"
                                label="Surname"
                            ></v-text-field>
                        
                            <v-text-field
                                v-model="phone.value.value"
                                :counter="7"
                                :error-messages="phone.errorMessage.value"
                                label="Phone Number"
                            ></v-text-field>
   
                            <v-select
                                v-model="country.value.value"
                                :items="countries"
                                item-title="name"
                                item-value="code"
                                :error-messages="country.errorMessage.value"
                                label="Country"
                            ></v-select>
                            <v-text-field
                                v-model="city.value.value"
                                :error-messages="city.errorMessage.value"
                                label="City"
                            ></v-text-field>
                            <v-text-field
                                v-model="address.value.value"
                                :error-messages="address.errorMessage.value"
                                label="Address"
                            ></v-text-field>
                            <v-responsive class="" max-width="140">
                            <v-text-field
                                v-model="province.value.value"
                                :error-messages="province.errorMessage.value"
                                label="Province/State"
                                hint="2 charachters"
                                counter="2"
                            ></v-text-field>
                          </v-responsive>
                            <v-text-field
                                v-model="postal.value.value"
                                :counter="10"
                                :error-messages="postal.errorMessage.value"
                                label="Postal/Zip Code"
                            ></v-text-field>
                                                                                                  
                        </v-container>      
                        </v-card>             

                
            </v-col>
            <v-col>
                <v-card class="mx-auto" color="grey-lighten-4" max-width="400">         
                        <h4 class="ml-4 mt-4">Card Details</h4>
                        <v-container class="">
                        
                    <v-text-field
                                    v-model="cardnumber.value.value"
                                    :counter="16"
                                    :error-messages="cardnumber.errorMessage.value"
                                    label="Card Number"
                                    
                                    v-bind:on-update:model-value="validateCard(cardnumber.value.value)"
                                ></v-text-field>
                
                                <img class="float-right" :src="cardImage" width="80" />
                          <v-responsive class="" max-width="120">
                    <v-text-field
                        v-model="cvcnumber.value.value"
                        width="100"
                        :counter="3"
                        :error-messages="cvcnumber.errorMessage.value"
                        type="number"
                        label="CVC/CVD"
                    ></v-text-field>
                    
                  </v-responsive>
                  <v-row class="ml-0">
                    <v-responsive class="ml-10" max-width="120">
                    <v-select
                                v-model="month.value.value"
                                :items="months"
                                :error-messages="month.errorMessage.value"
                                label="Month"
                    ></v-select>
                  </v-responsive>

                  <v-responsive class="" max-width="120">
                    <v-select
                                v-model="year.value.value"
                                :items="years"
                                :error-messages="year.errorMessage.value"
                                label="Year"
                    ></v-select>
                  </v-responsive>
                  
                </v-row>
                </v-container>
                
                </v-card>
               
               
               
                
            </v-col>
        </v-row>    
        <v-row>
            <v-card class="mx-auto " color="grey-lighten-4" flat  max-width="400">                                 
                        <v-container class="">
            <v-btn class="me-4" color="indigo-darken-3" type="submit" :disabled="!isDone" >submit</v-btn>
            <v-btn @click="handleReset">clear</v-btn>
            </v-container>
            </v-card>
        </v-row>  
    </form>      
    <div v-if="HasBillingId">
    <v-card class="ml-5 mr-5 mb-5" v-if="!IsUserExpired" color="orange-lighten-2"
                            title="Notice: You already have an active subscription" >
      <v-container>
        <h4>If you want to update your billing info, you need to cancel the current subscription</h4>
      <p>Billing id: {{ user.billing_id }}</p>
        <v-btn color="blue" v-on:click="cancelSub()" >Cancel subscription</v-btn> 
        
      </v-container>
      <div class="pa-5 text-grey-darken-4 font-weight-bold ">                  
        <p>Renewed {{ user.last_payment }}</p>
        <p>Next Renewal:  {{ user.expiry_date }}</p>                                  
      </div>
    </v-card>    
    <v-card class="ml-5 mr-5 mb-5" v-if="IsUserExpired" color="orange-lighten-2"
                            title="Notice: Account expired" >
      <v-container>
        <h4>Your subscription has expired. 
          If you want to update your billing info, you need to cancel the billing</h4>
      <p>Billing id: {{ user.billing_id }}</p>
        <v-btn color="blue" v-on:click="cancelSub()" >Cancel subscription</v-btn> 
        
      </v-container>
      <div class="pa-5 text-grey-darken-4 font-weight-bold ">
        <p>Renewed {{ user.last_payment }}</p>
        <p>Next Renewal:  {{ user.expiry_date }}</p>
      </div>
    </v-card> 
    
  </div>
    
</v-card> 

        </v-container>
  
        </v-main>
        
    </v-layout>
 
  </template>

  <script setup>
    import { useUserStore } from '../stores/user'
    import { computed } from 'vue'
    import axios from "axios";
    const user = useUserStore();        

    import { ref } from 'vue'
    import { useField, useForm } from 'vee-validate'
    import AppBar from '../components/AppBar.vue'
  
    const { handleSubmit, handleReset } = useForm({
      validationSchema: {
        name (value) {
          if (value?.length >= 2) return true  
          return 'Name needs to be at least 2 characters.'
        },
        city (value) {
          if (value?.length >= 2) return true  
          return 'City name needs to be at least 2 characters.'
        },
        address (value) {
          if (value?.length >= 2) return true  
          return 'Address needs to be at least 2 characters.'
        },
        province (value) {
          if (value?.length == 2) return true  
          return 'Province needs to be 2 characters.'
        },        
        surname (value) {
          if (value?.length >= 2) return true  
          return 'Surname needs to be at least 2 characters.'
        },
        postal (value) {
          if (value?.length > 4 && /[0-9-]+/.test(value)) return true  
          return 'Postal/Zip Code needs to be at least 5 digits.'
        },
        phone (value) {
          if (value?.length > 9 && /[0-9-]+/.test(value)) return true  
          return 'Phone number needs to be at least 9 digits.'
        },       
        country (value) {
          if (value) return true  
          return 'Select country'
        },
        cardnumber (value)
        {
          if (value?.length > 15 && /[0-9-]+/.test(value)) return true  
          return 'Card Number needs to be 16 digits'
        },        
        cvcnumber (value)
        {
          if (value?.length > 2 && /[0-9-]+/.test(value)) return true  
          return 'CVC needs to be 3 digits'
        },        
        year (value) {
          if (value) return true  
          return 'Select year.'
        },
        month(value) {
          if (value) return true  
          return 'Select month.'
        },
      },
    })
    const name = useField('name')
    const surname = useField('surname')
    const city = useField('city')
    const address = useField('address')
    const province =  useField('province')
    const postal = useField('postal')
    const phone = useField('phone')
    
    const country = useField('country')
    

    const cardnumber = useField('cardnumber')
    const cvcnumber = useField('cvcnumber')
    const year = useField('year')
    const month = useField('month')
  

    const countries = ref([
  { name: "Afghanistan", code: "AF" },
  { name: "Albania", code: "AL" },
  { name: "Algeria", code: "DZ" },
  { name: "Andorra", code: "AD" },
  { name: "Angola", code: "AO" },
  { name: "Antigua and Barbuda", code: "AG" },
  { name: "Argentina", code: "AR" },
  { name: "Armenia", code: "AM" },
  { name: "Australia", code: "AU" },
  { name: "Austria", code: "AT" },
  { name: "Azerbaijan", code: "AZ" },
  { name: "Bahamas", code: "BS" },
  { name: "Bahrain", code: "BH" },
  { name: "Bangladesh", code: "BD" },
  { name: "Barbados", code: "BB" },
  { name: "Belarus", code: "BY" },
  { name: "Belgium", code: "BE" },
  { name: "Belize", code: "BZ" },
  { name: "Benin", code: "BJ" },
  { name: "Bhutan", code: "BT" },
  { name: "Bolivia", code: "BO" },
  { name: "Bosnia and Herzegovina", code: "BA" },
  { name: "Botswana", code: "BW" },
  { name: "Brazil", code: "BR" },
  { name: "Brunei", code: "BN" },
  { name: "Bulgaria", code: "BG" },
  { name: "Burkina Faso", code: "BF" },
  { name: "Burundi", code: "BI" },
  { name: "Cabo Verde", code: "CV" },
  { name: "Cambodia", code: "KH" },
  { name: "Cameroon", code: "CM" },
  { name: "Canada", code: "CA" },
  { name: "Central African Republic", code: "CF" },
  { name: "Chad", code: "TD" },
  { name: "Chile", code: "CL" },
  { name: "China", code: "CN" },
  { name: "Colombia", code: "CO" },
  { name: "Comoros", code: "KM" },
  { name: "Congo", code: "CG" },
  { name: "Costa Rica", code: "CR" },
  { name: "Croatia", code: "HR" },
  { name: "Cuba", code: "CU" },
  { name: "Cyprus", code: "CY" },
  { name: "Czechia", code: "CZ" },
  { name: "Denmark", code: "DK" },
  { name: "Djibouti", code: "DJ" },
  { name: "Dominica", code: "DM" },
  { name: "Dominican Republic", code: "DO" },
  { name: "Ecuador", code: "EC" },
  { name: "Egypt", code: "EG" },
  { name: "El Salvador", code: "SV" },
  { name: "Equatorial Guinea", code: "GQ" },
  { name: "Eritrea", code: "ER" },
  { name: "Estonia", code: "EE" },
  { name: "Eswatini", code: "SZ" },
  { name: "Ethiopia", code: "ET" },
  { name: "Fiji", code: "FJ" },
  { name: "Finland", code: "FI" },
  { name: "France", code: "FR" },
  { name: "Gabon", code: "GA" },
  { name: "Gambia", code: "GM" },
  { name: "Georgia", code: "GE" },
  { name: "Germany", code: "DE" },
  { name: "Ghana", code: "GH" },
  { name: "Greece", code: "GR" },
  { name: "Grenada", code: "GD" },
  { name: "Guatemala", code: "GT" },
  { name: "Guinea", code: "GN" },
  { name: "Guinea-Bissau", code: "GW" },
  { name: "Guyana", code: "GY" },
  { name: "Haiti", code: "HT" },
  { name: "Honduras", code: "HN" },
  { name: "Hungary", code: "HU" },
  { name: "Iceland", code: "IS" },
  { name: "India", code: "IN" },
  { name: "Indonesia", code: "ID" },
  { name: "Iran", code: "IR" },
  { name: "Iraq", code: "IQ" },
  { name: "Ireland", code: "IE" },
  { name: "Israel", code: "IL" },
  { name: "Italy", code: "IT" },
  { name: "Jamaica", code: "JM" },
  { name: "Japan", code: "JP" },
  { name: "Jordan", code: "JO" },
  { name: "Kazakhstan", code: "KZ" },
  { name: "Kenya", code: "KE" },
  { name: "Kiribati", code: "KI" },
  { name: "Korea, North", code: "KP" },
  { name: "Korea, South", code: "KR" },
  { name: "Kosovo", code: "XK" },
  { name: "Kuwait", code: "KW" },
  { name: "Kyrgyzstan", code: "KG" },
  { name: "Laos", code: "LA" },
  { name: "Latvia", code: "LV" },
  { name: "Lebanon", code: "LB" },
  { name: "Lesotho", code: "LS" },
  { name: "Liberia", code: "LR" },
  { name: "Libya", code: "LY" },
  { name: "Liechtenstein", code: "LI" },
  { name: "Lithuania", code: "LT" },
  { name: "Luxembourg", code: "LU" },
  { name: "Madagascar", code: "MG" },
  { name: "Malawi", code: "MW" },
  { name: "Malaysia", code: "MY" },
  { name: "Maldives", code: "MV" },
  { name: "Mali", code: "ML" },
  { name: "Malta", code: "MT" },
  { name: "Marshall Islands", code: "MH" },
  { name: "Mauritania", code: "MR" },
  { name: "Mauritius", code: "MU" },
  { name: "Mexico", code: "MX" },
  { name: "Micronesia", code: "FM" },
  { name: "Moldova", code: "MD" },
  { name: "Monaco", code: "MC" },
  { name: "Mongolia", code: "MN" },
  { name: "Montenegro", code: "ME" },
  { name: "Morocco", code: "MA" },
  { name: "Mozambique", code: "MZ" },
  { name: "Myanmar", code: "MM" },
  { name: "Namibia", code: "NA" },
  { name: "Nauru", code: "NR" },
  { name: "Nepal", code: "NP" },
  { name: "Netherlands", code: "NL" },
  { name: "New Zealand", code: "NZ" },
  { name: "Nicaragua", code: "NI" },
  { name: "Niger", code: "NE" },
  { name: "Nigeria", code: "NG" },
  { name: "North Macedonia", code: "MK" },
  { name: "Norway", code: "NO" },
  { name: "Oman", code: "OM" },
  { name: "Pakistan", code: "PK" },
  { name: "Palau", code: "PW" },
  { name: "Panama", code: "PA" },
  { name: "Papua New Guinea", code: "PG" },
  { name: "Paraguay", code: "PY" },
  { name: "Peru", code: "PE" },
  { name: "Philippines", code: "PH" },
  { name: "Poland", code: "PL" },
  { name: "Portugal", code: "PT" },
  { name: "Qatar", code: "QA" },
  { name: "Romania", code: "RO" },
  { name: "Russia", code: "RU" },
  { name: "Rwanda", code: "RW" },
  { name: "Saint Kitts and Nevis", code: "KN" },
  { name: "Saint Lucia", code: "LC" },
  { name: "Saint Vincent and the Grenadines", code: "VC" },
  { name: "Samoa", code: "WS" },
  { name: "San Marino", code: "SM" },
  { name: "Sao Tome and Principe", code: "ST" },
  { name: "Saudi Arabia", code: "SA" },
  { name: "Senegal", code: "SN" },
  { name: "Serbia", code: "RS" },
  { name: "Seychelles", code: "SC" },
  { name: "Sierra Leone", code: "SL" },
  { name: "Singapore", code: "SG" },
  { name: "Slovakia", code: "SK" },
  { name: "Slovenia", code: "SI" },
  { name: "Solomon Islands", code: "SB" },
  { name: "Somalia", code: "SO" },
  { name: "South Africa", code: "ZA" },
  { name: "South Sudan", code: "SS" },
  { name: "Spain", code: "ES" },
  { name: "Sri Lanka", code: "LK" },
  { name: "Sudan", code: "SD" },
  { name: "Suriname", code: "SR" },
  { name: "Sweden", code: "SE" },
  { name: "Switzerland", code: "CH" },
  { name: "Syria", code: "SY" },
  { name: "Taiwan", code: "TW" },
  { name: "Tajikistan", code: "TJ" },
  { name: "Tanzania", code: "TZ" },
  { name: "Thailand", code: "TH" },
  { name: "Timor-Leste", code: "TL" },
  { name: "Togo", code: "TG" },
  { name: "Tonga", code: "TO" },
  { name: "Trinidad and Tobago", code: "TT" },
  { name: "Tunisia", code: "TN" },
  { name: "Turkey", code: "TR" },
  { name: "Turkmenistan", code: "TM" },
  { name: "Tuvalu", code: "TV" },
  { name: "Uganda", code: "UG" },
  { name: "Ukraine", code: "UA" },
  { name: "United Arab Emirates", code: "AE" },
  { name: "United Kingdom", code: "GB" },
  { name: "United States", code: "US" },
  { name: "Uruguay", code: "UY" },
  { name: "Uzbekistan", code: "UZ" },
  { name: "Vanuatu", code: "VU" },
  { name: "Vatican City", code: "VA" },
  { name: "Venezuela", code: "VE" },
  { name: "Vietnam", code: "VN" },
  { name: "Yemen", code: "YE" },
  { name: "Zambia", code: "ZM" },
  { name: "Zimbabwe", code: "ZW" }
]);
    
    name.value.value = user.name;
    surname.value.value = user.surname;
    city.value.value = user.city;
    address.value.value = user.address;
    province.value.value = user.province;
    postal.value.value = user.postal;
    phone.value.value = user.phone;
    country.value.value = user.country;
    

    
    const years = Array.from({ length: 11 }, (_, index) => new Date().getFullYear() + index);
    
    const months = ref(["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"]);
    var cardType = ref("test");
    var cardImage = ref("");
    const isDone = ref(true);
    const isExpired = ref(false);

    var now = new Date();
    var exp = new Date(user.expiry_date);

    now.setHours(0,0,0,0);
    
    if (exp < now) {
      isExpired.value = true;
    } else {
      isExpired.value = false;    
    }  
    
    const IsUserExpired = computed(() => isExpired.value)
    const HasBillingId = computed(() => {
      return user.billing_id > 0
    })
    
    
    const submit = handleSubmit(values => { 
            isDone.value  = false;
            values.year = values.year.toString().substr(-2);    
            values.user_id = user.id;           
            values.email = user.email;      
            axios.post("https://mediatrunk.net/mobilevids/new_billing.php", values)
            .then(response => {
                
                if(response.data.status == 0)
                {
                  alert(response.data.reason);                  
                }                
                else
                {
                  alert(response.data.reason);
                  user.billing_id = response.data.bID;                          
                  user.servers[0] = response.data.server;                                                         
                }
                isDone.value  = true;
            })
            .catch(error => {
                this.errorMessage = error.message;
                console.error("There was an error!", error);
                alert(error);
                isDone.value  = true;
            });
    })

   

    function verifyCard(number) {   
    var re = new RegExp("^4");
    if (number.match(re) != null)
        return "Visa";
     if (/^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(number)) 
        return "Mastercard";

    re = new RegExp("^3[47]");
    if (number.match(re) != null)
        return "AMEX";

    re = new RegExp("^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)");
    if (number.match(re) != null)
        return "Discover";

    re = new RegExp("^36");
    if (number.match(re) != null)
        return "Diners";

    re = new RegExp("^30[0-5]");
    if (number.match(re) != null)
        //return "Diners - Carte Blanche";
        return "Diners";

    re = new RegExp("^35(2[89]|[3-8][0-9])");
    if (number.match(re) != null)
        return "JCB";

    re = new RegExp("^(4026|417500|4508|4844|491(3|7))");
    if (number.match(re) != null)
        return "Visa Electron";
    return "";
    }
    function cancelSub()
    {
      let Data = {BillingId: user.billing_id, Email: user.email};
      axios.post("https://mediatrunk.net/mobilevids/cancel_sub.php", Data)
            .then(response => {                
                if(response.data.status[0] == 1)
                {
                  alert(response.data.message[0]);
                  user.billing_id = 0;
                }                
                else
                {
                  alert("Request Failed:\nMessage: " + response.data.message[0]+"\nCode: " + response.data.status[0]);                  
                }
              });

    }
    function validateCard(number)
    {

      if(number!==undefined)
      {
        number = number.replace(/\D/g,'');
        cardnumber.value.value = number;
        const base_path = "./imgs/";
        cardType.value = verifyCard(number);
        if(cardType.value!="" && number.length>15)
        {
          cardImage.value = base_path + cardType.value + ".png";
        }
        else
        cardImage.value = "";  
      }
      else
      cardImage.value = "";
          
     
    }
  </script>
