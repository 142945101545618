<template>
    <v-card max-width="1200" class="mx-auto" color="grey-lighten-4">
      
      <v-layout>                
        <AppBar />
        <v-main>
          <v-container fluid >
            <h2>Server Info</h2>
            <div v-if="this.user.servers.length > 0">
            <v-container v-for="(item) in this.user.servers" :key="item.id" >
              <v-card>
                  <v-container><h4>Server: {{item.name}}</h4>
                   
                    <v-btn color= "secondary" class="float-right" v-on:click="RestorePassword(item.id)" :disabled="!doneRestorePwd">Restore Password</v-btn>  
                    <v-btn color= "primary" class="float-right mr-1" v-on:click="Launch(item.URL)">Launch</v-btn>  
                    

                  <p>Address: {{ item.URL }}</p>                  
              
                  <p>Status: <v-chip color="green"  v-if="item.status==1">Running</v-chip>                     
                  <v-chip color="red" v-else-if="item.status==0">Down</v-chip>  
                  <v-chip color="yellow" v-else >Unknown</v-chip>
                  </p>
                  <p class="mt-1"> Server load 
                  <v-chip color="green" v-if="item.load==1" >Medium</v-chip> 
                  <v-chip color="teal" v-else-if="item.load==0">Low</v-chip>                 
                  <v-chip color="red" v-else-if="item.load==2" >High</v-chip> </p>                                                  
                  
                  <h4 class="mt-4">Account Details:</h4>
                    <p>Username: {{ item.username }}</p>
                    <p class="mt-1"> Status:
                    <v-chip color="red" v-if="item.acc_status=='Disabled'"> {{ item.acc_status }} </v-chip>
                    <v-chip color="green" v-else>  {{ item.acc_status }} </v-chip>
                    </p>


                </v-container>                                
              </v-card>
            </v-container>
            </div>
            <div v-else>
              <v-alert color="warning" type="warning" text="It seems like you have not been assigned to any server yet. You can request access here."> 
                <v-btn color= "primary" class="float-right mr-1" v-on:click="RequestAccess()" :disabled="!doneRequest">Request Access</v-btn>  
              </v-alert>
            </div>

          </v-container>
        </v-main>
      </v-layout>
    </v-card>
  </template>

<script>
import { useUserStore } from '../stores/user'
import AppBar from '../components/AppBar.vue'
import axios from "axios";
export default {
  data: () => ({
      status: "Unknown",     
      doneRestorePwd:true,
      doneRequest: true,      
    }),
  setup() {
    const user = useUserStore();    
    return{user}
   
  }, 
  mounted(){
    const user = useUserStore();  
    console.log(user) ;
   let self = this;
    for (const item of this.user.servers)
    {      
      self.StatusCheck(item.URL,item.id,user.id);
    }
    
  },
  methods:{
    HasAccess()
    {
      var date = new Date(this.user.last_payment);
      var now = new Date();
      now.setHours(0,0,0,0);      
      if (date < now) {
        
        return false;
      } else {
        
        return true;
      }        
    },
    RequestAccess()
    {
      this.doneRequest = false;
      if(this.HasAccess())
      {
        let Data = {                    
                ID:this.user.id,
                Email:this.user.email
            }                                    

            axios.post("https://mediatrunk.net/mobilevids/request_access.php",Data)
            .then(response => {              
              alert(response.data.reason);
              if(response.data.status == 1)
              {
                this.user.servers[0] = response.data.server;                
                for (const item of this.user.servers)
                {                
                  this.StatusCheck(item.URL,item.id);
                }
              }
              
              
              this.doneRequest = true;
            })
            .catch(error => {
                this.errorMessage = error.message;
                this.doneRequest = true;
                console.error("There was an error!", error);
            });      
      }else
      {
        alert("You account has expired, please renew your billing information");
      }
        
    },
    Launch(URL){
      window.open(URL,'_blank');                    
    },
    RestorePassword(ServerId)
    {
      let self = this;
      self.doneRestorePwd = false;
      let RecoverData = {
        email:this.user.email,
        server_id:ServerId 
            }                        
            axios.post("https://mediatrunk.net/mobilevids/cron/recover_password.php", RecoverData)
            .then(response => {
                        
                alert(response.data.msg);               
                self.doneRestorePwd = true;
            })
            .catch(error => {
                this.errorMessage = error.message;
                console.error("There was an error!", error);
                alert(error); 
                self.doneRestorePwd = true;
            });
    },
    StatusCheck(server,id,user_id)
        {              
          let Data = {
                Server:server,      
                id:id,
                userId: user_id       
            }                                    

            axios.post("https://mediatrunk.net/mobilevids/ping.php",Data)
            .then(response => {
              
              for (const item of this.user.servers)
              {                
                if(item.id == id)
                {
                  
                  item.load = response.data.load;                              
                  item.status = -1;
                  item.username = response.data.username;
                  item.acc_status = response.data.acc_status;
                  if(response.data.status == 0)
                    item.status = 0;
                  else                
                    item.status = 1;    
                    
                }                                
              }
            })
            .catch(error => {
                this.errorMessage = error.message;
                console.error("There was an error!", error);
            });
        }
  },
  components: {
    AppBar
    },

}
</script>